<template>
  <div class="marquee-list-wrap">
    <div class="search-wrap">
      <el-input
        v-model="search_text"
        class="search-bar"
        placeholder="請輸入門市名稱"
        :prefix-icon="Search"
        clearable
        @keyup.enter="handleSearch"
        @clear="handleSearch"
      />
    </div>
    <el-table
      ref="listTable"
      :data="renderList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="store_name" label="門市" width="200">
        <template #default="scope">
          <span>{{ scope.row.store_erp_id }} {{ scope.row.store_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="內容" />
      <el-table-column fixed="right" align="right" width="230">
        <template #default="scope">
          <p class="edit-button" @click="openDialog('marqueeSetting', { init: scope.row, index: scope.$index })">
            編輯
          </p>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="pageTotal"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { ref, computed, defineAsyncComponent, getCurrentInstance, watch } from 'vue';
import { Search } from '@element-plus/icons-vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { useRoute, useRouter } from 'vue-router';
import { useDialog } from '@/utils/dialog';
export default {
  name: 'marquee',
  components: {
    MarqueeDialog: defineAsyncComponent(() => import('@/components/information/MarqueeDialog.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { dialogTemplate, openDialog, closeDialog } = useDialog();

    //設定參數取得資料
    const list = ref([]);
    const search_text = ref('');
    const listTable = ref(null);
    const currentPage = ref(1);
    const { getMarqueeList, getPageSize } = useGetters('marquee', ['getMarqueeList', 'getPageSize']);
    const doGetMarqueeList = useActions('marquee', ['doGetMarqueeList']);

    //初始化
    const query = { page: route.query.page ? route.query.page : 1 };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      await doGetMarqueeList();
      getList();
    });
    const renderList = computed(() =>
      list.value.slice((currentPage.value - 1) * getPageSize.value, currentPage.value * getPageSize.value)
    );
    const pageTotal = computed(() => list.value.length);

    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      () => {
        if (route.query.page) {
          currentPage.value = currentPage.value = Number(route.query.page);
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //取得清單
    const getList = (text) => {
      if (text) {
        const f_list = getMarqueeList.value.filter((e) => e.store_name.includes(text));
        list.value = f_list;
      } else {
        list.value = getMarqueeList.value;
      }
    };

    //搜尋
    const handleSearch = () => {
      currentPage.value = 1;
      getList(search_text.value);
    };

    //更新list
    const refreshList = async (data) => {
      renderList.value[data.index].value = data.value;
      proxy.$message({
        type: 'success',
        message: `修改成功`,
      });
      closeDialog();
    };

    //離開清空vuex
    const RESET = useMutations('marquee', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/information/marquee')) RESET();
      }
    );
    return {
      Search,
      dialogTemplate,
      openDialog,
      getPageSize,
      search_text,
      listTable,
      currentPage,
      renderList,
      pageTotal,
      handleCurrentChange,
      handleSearch,
      refreshList,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.marquee-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.search-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 200px;
}
.search-bar {
  width: 200px;
}
.edit-button {
  width: 160px;
  color: $color-green;
  cursor: pointer;
  @include base-hover;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
